function handleInstagramFeedLoadMore(hashtag, ordering) {
	var nextPage = 2;

	// attempt to resize several times: from 100ms to 2 minutes (to handle all connections)
	resizeInstagramMasonryAfterXmiliSeconds({ delays: [100, 1000, 2000, 4000, 8000, 15000, 30000, 60000, 120000] });

	$('.js-ccc-instagram-feed-load-more').click(function(event) {
		event.preventDefault();

		var loadMoreButton = $(this);

		jQuery.ajax( {
			url: '/ajax-instagram-hastag/?page=' + nextPage + '&ordering=' + ordering + '&hashtag=' + hashtag,
			type: "GET",
			dataType: "json",
			data: {}
		}).done( function( response ) {
			nextPage += 1;

			var hasMore = response.has_more;
			var html = response.html;

			if (! hasMore) {
				loadMoreButton.hide();
			}

			setTimeout(function() {
				$('.js-ccc-instagram-feed-masonry').append(html);
				setTimeout(function() {
					$('.js-ccc-instagram-feed-masonry').masonry('appended', html);
					$('.js-ccc-instagram-feed-masonry').masonry('reloadItems');
					$('.js-ccc-instagram-feed-masonry').masonry('layout');
				}, 100);
			}, 100); // without that timeout (100ms), the images don't always get repositioned properly

			resizeInstagramMasonryAfterXmiliSeconds({ delays: [1000, 5000] });
		});
	});
}

/**
 * Doing the masonry refresh again and again for slower computers (just in case)
 * this won't be visible on faster machines (as the resize isn't visible if the images are already properly aligned)
 * This is hacky but necessary as `setMasonry.imagesLoaded().done` doesn't seem to work in all cases
 * @param delays
 */
function resizeInstagramMasonryAfterXmiliSeconds({ delays = [1000] }) {
	delays.forEach(function(delay) {
		setTimeout(function () {
			$('.js-ccc-instagram-feed-masonry').masonry('reloadItems');
			$('.js-ccc-instagram-feed-masonry').masonry('layout');
		}, delay);
	});
}

$(document).ready(function () {
	$('.js-ccc-instagram-feed').each(function () {
		var hashtag = $(this).data('hashtag');
		var ordering = $(this).data('ordering');
		handleInstagramFeedLoadMore(hashtag, ordering);
	});
});
